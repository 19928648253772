import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { projects } from "../../components/data/projects"
import { StaticImage } from "gatsby-plugin-image"

import ProjectHeader from "../../components/project-header/project-header"
import ReadNext from "../../components/read-next/read-next"
import { RoughNotation } from "react-rough-notation"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const theme = projects['poeticAI'].themeColor;

const ProjectPage = () => (
  <>
    <Seo title="Poetic AI" />
    <ProjectHeader projectObj={projects['poeticAI']} />
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
        <p className={`primary lh`}>In the summer of 2018, I was experimenting with RNN models and trying to find some creative applications. To test its sequential learning ability, I made an <RoughNotation type={`underline`} show multiline color={theme}>LSTM model</RoughNotation> in python using Keras and fed it a dataset of poems. After training the model character by character, the model was then asked to predict new characters for an input of random words. The result was an entire poem written by the neural network.</p>
        <p className={`primary lh`}>It was really cool to see the network learn the sentence structure of poems. The model also generated rhyming words in some of the iterations. It does have some noise since it's trained character by character, but the overall output is extremely intriguing.</p>
        <a href={`https://github.com/AbhinavThukral97/poetry-using-RNN/blob/master/poetry.ipynb`} target={`_blank`}><button>View the code on Github <span className={`icon`}><FontAwesomeIcon icon={faExternalLinkAlt} /></span></button></a>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/poetic-ai/poem.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>One of the generated poems</p>
        </div>
        <p className={`add-margin-top primary lh`}>I later trained the same model word by word instead of character by character on Facebook's stories' dataset (The Goldilock's Project dataset) to write short 100 word stories.</p>
      </div>
    </section>
    <ReadNext projectObj={projects['aesop']} />
  </>
)

export default ProjectPage